import React from "react";

import {Page} from "./page";

// Stores
import {ApiStore} from "../stores/api-store";

// Components
import {ServerError} from "../components/interstitials";
import {PageTitles, withRideOctaneTitle} from "./octane-title";


// 500 Server Error Page
export function serverError() {

    const store = Page.createStore({
        isPage500: true,
        stores: {
            apiStore: new ApiStore(),
        }
    });

    const titleWords = [PageTitles.SERVER_ERROR_TITLE];
    const component = Page.createComponent({
        content: withRideOctaneTitle(<ServerError origin="500-server-error"/>, titleWords),
    });

    logServerError(titleWords);
    return {store, component};
}

const logServerError = (titleWords) => {
    console.error(`ServerErrorPage: ${titleWords}`);
};
